import rallys from "./../../assets/paddle.svg";
import shots from "./../../assets/shots.svg";
import matches from "./../../assets/games.svg";
import PictureView from "./PictureView";
import { useState } from "react";

const parseCount = (num) => {
  if (num >= 1000) {
    return (
      <div className='flex flex-row justify-center font-avenirmedium text-3xl text-center items-end'>
        <span className='font-avenirmedium text-5xl sm:text-6xl lg:text-7xl'>
          {(num / 1000).toFixed(0)}
        </span>
        <span className=''>.</span>
        <span className='font-avenirmedium text-5xl sm:text-6xl lg:text-7xl'>
          {(num / 1000).toFixed(1)[2]}k
        </span>
      </div>
    );
  }
  return num;
};

export default function PlayerHeader({
  singles_dupr,
  doubles_dupr,
  dupr_code,
  shortaddress,
  shotsCount,
  ralliesCount,
  gamesCount,
  first_nm,
  last_nm,
  gender,
  age,
  location,
  dom_hand,
  paddle,
  dupr,
}) {
  const [pictureView, setPictureView] = useState(false);

  return (
    <>
      <div
        id='player-header'
        className='hidden lg:grid mx-auto w-full lg:grid-cols-2 items-end font-avenir text-[#2b2b2b] mt-4 '>
        <div className='flex h-full '>
          {/* Pic and Name */}
          <div className='h-full flex flex-col justify-end text-center lg:px-4 '>
            <div
              onClick={() => {
                setPictureView(!pictureView);
              }}
              className='inline-flex h-full w-full lg:w-36 lg:text-7xl rounded-full align-end items-center justify-center bg-[#18BC9C] text-white font-semibold hover:cursor-pointer'>
              {first_nm[0]}
            </div>

            <div className='hidden lg:inline font-bold'>
              {first_nm} {last_nm}
            </div>
          </div>
          {/* About */}
          <div className='h-full flex flex-col justify-between lg:pl-8 lg:text-lg w-full '>
            <div className='block lg:hidden font-bold'>
              {first_nm} {last_nm}
            </div>
            <div className='mb-1 flex-row flex font-avenirmedium  '>
              Gender:
              <p className='font-avenirdemibold ml-1 lg:text-xl'>
                {gender === "F" ? "Female" : "Male"}
              </p>
            </div>
            <div className='mb-1 flex-row flex font-avenirmedium'>
              Age:{" "}
              <p className='font-avenirdemibold ml-1 lg:text-xl'>
                {age || "N/A"}
              </p>
            </div>
            <div className='mb-1 flex-row flex font-avenirmedium'>
              Location:
              <p className='font-avenirdemibold ml-1 lg:text-xl'>
                {/* slicing this cause it gets the country and thats not needed now */}
                {shortaddress.slice(0, shortaddress.length - 4) || "N/A"}
              </p>
            </div>
            <div className='mb-1 flex-row flex font-avenirmedium'>
              Handedness:
              <p className='font-avenirdemibold ml-1 lg:text-xl'>
                {dom_hand === "R" ? "Right" : "Left"}
              </p>
            </div>
            <div className='flex items-center font-avenirmedium'>
              DUPR:
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='w-4 h-4 mx-1'>
                <path
                  fillRdive='evenodd'
                  d='M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z'
                  cdivprdive='evenodd'
                />
              </svg>
              <span className='font-avenirdemibold lg:text-xl'>
                {singles_dupr || "NR"}
              </span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='w-4 h-4 mx-2'>
                <path d='M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z' />
              </svg>
              <span className='font-avenirdemibold lg:text-xl'>
                {doubles_dupr || "NR"}
              </span>
            </div>
          </div>
        </div>
        {/* Stats */}
        <div className='grid grid-cols-3 items-center'>
          <div className='text-center border-r-pkl-400 border-r-4'>
            <img
              src={matches}
              className='w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 object-stretch mx-auto'
            />
            <div className='font-avenirbold text-xl'>MATCHES</div>
            <div className='font-avenirmedium md:text-5xl lg:text-7xl'>
              {parseCount(gamesCount)}
            </div>
          </div>
          <div className='text-center  border-r-pkl-400 border-r-4'>
            <img
              src={rallys}
              className='w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 object-contain mx-auto'
            />
            <div className='font-avenirbold text-xl'>RALLYS</div>
            <div className='font-avenirmedium md:text-5xl lg:text-7xl'>
              {parseCount(ralliesCount)}
            </div>
          </div>
          <div className='text-center '>
            <img
              src={shots}
              className='w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 object-contain mx-auto'
            />
            <div className='font-avenirbold text-xl'>SHOTS</div>
            <div className='font-avenirmedium md:text-5xl lg:text-7xl'>
              {parseCount(shotsCount)}
            </div>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div className='mx-auto w-full grid lg:hidden items-end font-avenir text-[#2b2b2b] border-b-[3px] border-b-pkl-400'>
        <div className='flex pt-4'>
          <div className='flex w-1/3 justify-center'>
            <div
              onClick={() => {
                setPictureView(!pictureView);
              }}
              className='inline-flex w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 self-center rounded-full align-end items-center justify-center bg-[#18BC9C] text-white font-semibold hover:cursor-pointer'>
              {/* temporary pfp */}
              {first_nm[0]}
            </div>
          </div>
          <div className='w-2/3  py-2 text-sm sm:text-base'>
            <div className='flex flex-row justify-between items-center'>
              <div className='font-avenirmedium text-2xl md:text-3xl'>
                {first_nm} {last_nm}
              </div>
              <div className='flex h-full w-14 sm:w-20 py-1 text-white rounded-md justify-center self-center text-center bg-pkl-500'>
                Edit
              </div>
            </div>
            <div className='flex flex-row pt-4'>
              <div className='pr-4'>
                <div className='mb-1 flex-row flex font-avenirmedium '>
                  Gender:
                  <p className='font-avenirdemibold ml-1  text-sm sm:text-base'>
                    {gender === "F" ? "Female" : "Male"}
                  </p>
                </div>
                <div className='mb-1 flex-row flex font-avenirmedium'>
                  Age:
                  <p className='font-avenirdemibold ml-1  text-sm sm:text-base'>
                    {age || "N/A"}
                  </p>
                </div>
                <div className='flex items-center font-avenirmedium'>
                  DUPR:
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className='w-4 h-4 mx-1'>
                    <path
                      fillRdive='evenodd'
                      d='M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z'
                      cdivprdive='evenodd'
                    />
                  </svg>
                  <span className='font-avenirdemibold  text-sm sm:text-base'>
                    {parseFloat(singles_dupr).toFixed(2) || "NR"}
                  </span>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 mx-2"
                  >
                    <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                  </svg>
                  <span className="font-avenirdemibold">
                    {doubles_dupr || "NR"}
                  </span> */}
                </div>
              </div>

              <div className=''>
                <div className='mb-1 flex-row flex font-avenirmedium'>
                  Handedness:
                  <p className='font-avenirdemibold ml-1 text-sm sm:text-base'>
                    {dom_hand === "R" ? "Right" : "Left"}
                  </p>
                </div>
                <div className='mb-1 flex-row flex font-avenirmedium'>
                  Paddle:
                  <p className='font-avenirdemibold ml-1 text-sm sm:text-base'>
                    {dom_hand === "R" ? "Right" : "Left"}
                  </p>
                </div>

                <div className='mb-1 flex-row flex font-avenirmedium'>
                  Location:
                  <p className='font-avenirdemibold ml-1 text-sm sm:text-base'>
                    {/* temporary until we can change short address to not include country */}
                    {shortaddress.slice(0, shortaddress.length - 4) || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-3 items-center mb-8'>
          <div className='text-center border-r-pkl-400 border-r-2'>
            <img src={matches} className='w-12 h-12 object-stretch mx-auto' />
            <div className='font-avenirdemibold text-base sm:text-lg'>
              MATCHES
            </div>
            <div className='font-avenirmedium text-5xl sm:text-6xl '>
              {parseCount(gamesCount)}
            </div>
          </div>
          <div className='text-center  border-r-pkl-400 border-r-2'>
            <img src={rallys} className='w-12 h-12  object-contain mx-auto' />
            <div className='font-avenirdemibold text-base sm:text-lg'>
              RALLYS
            </div>
            <div className='font-avenirmedium text-5xl sm:text-6xl'>
              {parseCount(ralliesCount)}
            </div>
          </div>
          <div className='text-center '>
            <img src={shots} className='w-12 h-12  object-contain mx-auto' />
            <div className='font-avenirdemibold text-base sm:text-lg'>
              SHOTS
            </div>
            <div className='font-avenirmedium text-5xl sm:text-6xl'>
              {parseCount(shotsCount)}
            </div>
          </div>
        </div>
      </div>

      <PictureView
        visible={pictureView}
        setVisible={() => {
          setPictureView(!pictureView);
        }}
      />
    </>
  );
}
