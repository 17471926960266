import { useLocation } from "wouter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function PlayerPeers({ peers = [] }) {
  const [location, setLocation] = useLocation();
  function renderPlayers(groupSize = 2) {
    const playerGroups = [];

    for (let i = 0; i < peers.length; i += groupSize) {
      playerGroups.push(peers.slice(i, i + groupSize));
    }

    return playerGroups.map((group, index) => (
      <div className={`p-2 flex border justify-between mb-4`} key={index}>
        {group.map(({ player_id, first_nm, last_nm, gender }) => (
          <div className='flex items-center text-left' key={player_id}>
            <div className='mx-auto relative inline-flex text-4xl h-16 w-16 sm:h-24 sm:w-24 rounded-full align-center items-center justify-center bg-[#18BC9C] text-white font-semibold mr-2 sm:mr-4'>
              {first_nm[0]}
            </div>
            <div>
              <div>
                <strong className='text-sm xs:text-lg sm:text-xl'>
                  {first_nm} {last_nm}
                </strong>
              </div>
              <div>
                <span className='text-sm'>{"Bethesda, MD"}</span>
              </div>
              <span className='text-sm'>Age: 20</span>
            </div>
          </div>
        ))}
      </div>
    ));
  }

  return (
    <>
      <div className='lg:w-1/2 p-4'>
        <h3 className='text-center font-semibold text-2xl mb-4'>
          Recent Teammates
        </h3>

        <div className='lg:hidden'>
          <div className='xs:hidden sm:hidden'>
            <Carousel
              className=''
              centerMode
              showThumbs={false}
              centerSlidePercentage={90}
              showArrows={false}
              showIndicators={false}
              renderIndicator={false}
              showStatus={false}
              swipeable>
              {renderPlayers(1)}
            </Carousel>
          </div>
          <div className='hidden xs:inline '>
            <Carousel
              className=''
              centerMode
              showThumbs={false}
              centerSlidePercentage={90}
              showArrows={false}
              showIndicators={false}
              renderIndicator={false}
              showStatus={false}
              swipeable>
              {renderPlayers(2)}
            </Carousel>
          </div>
        </div>
        <div className='hidden lg:inline'>{renderPlayers(1)}</div>
      </div>
    </>
  );
}
