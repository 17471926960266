import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useRef, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAtom } from "jotai";
import { Tooltip } from "@material-tailwind/react";
import classNames from "classnames";
import court from "./assets/pb_court.jpeg";
import dayjs from "dayjs";

import "./App.css";
import {
  Header,
  IntroModal,
  RightMouseIcon,
  LeftMouseIcon,
  RallyAndShotLog,
  LeftSideVideoPlayer,
  RightSideTools,
  TopTeam,
  BottomTeam,
  ShotModal,
} from "./components";

import {
  storeDefault,
  player1Atom,
  player2Atom,
  player3Atom,
  player4Atom,
  player1HandednessAtom,
  player2HandednessAtom,
  player3HandednessAtom,
  player4HandednessAtom,
  player1SexAtom,
  player2SexAtom,
  player3SexAtom,
  player4SexAtom,
  inputStyleAtom,
  playerListAtom,
  swapTeamSidesAtom,
  gameTypeAtom,
  youtubeLinkAtom,
  youtubeIdAtom,
  tournamentNameAtom,
  tournamentYearAtom,
  tournamentListAtom,
  gameNumberAtom,
  skillLevelAtom,
  detUserNameAtom,
  clicksAtom,
  ralliesAtom,
  pointNumberAtom,
  shotNumberAtom,
  elapsedAtom,
  elapsedSecondsAtom,
  servingTeamAtom,
  teamAScoreAtom,
  teamBScoreAtom,
  serverNumberAtom,
  thirdShotPlayerSideAtom,
  returnerSwitchAtom,
  serverSwitchAtom,
  teamAOriginalPosAtom,
  teamBOriginalPosAtom,
  displayIntroModalAtom,
  ballTypeAtom,
  ballListAtom,
  datePlayedAtom,
  topRightPlayerAtom,
  topLeftPlayerAtom,
  botLeftPlayerAtom,
  botRightPlayerAtom,
  currentServerPlayerAtom,
  inputModelAtom,
  inputModelsAtom,
} from "./atoms";
import Loading from "./components/Loading";
import { DataEntryToolHeader } from "./components/DataEntryTool";

function Det() {
  // refs
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const ccRef = useRef(null);
  const videoRef = useRef(null);

  // Controls
  const [inputStyle, setInputStyle] = useAtom(inputStyleAtom);
  const [swapTeamSides, setSwapTeamSides] = useAtom(swapTeamSidesAtom);
  const [gameType, setGameType] = useAtom(gameTypeAtom);

  // Game Info
  const [player1, setPlayer1] = useAtom(player1Atom);
  const [player2, setPlayer2] = useAtom(player2Atom);
  const [player3, setPlayer3] = useAtom(player3Atom);
  const [player4, setPlayer4] = useAtom(player4Atom);
  const [playerList, setPlayerList] = useAtom(playerListAtom);
  const [player1Handedness, setPlayer1Handedness] = useAtom(
    player1HandednessAtom
  );
  const [player2Handedness, setPlayer2Handedness] = useAtom(
    player2HandednessAtom
  );
  const [player3Handedness, setPlayer3Handedness] = useAtom(
    player3HandednessAtom
  );
  const [player4Handedness, setPlayer4Handedness] = useAtom(
    player4HandednessAtom
  );
  const [player1Sex, setPlayer1Sex] = useAtom(player1SexAtom);
  const [player2Sex, setPlayer2Sex] = useAtom(player2SexAtom);
  const [player3Sex, setPlayer3Sex] = useAtom(player3SexAtom);
  const [player4Sex, setPlayer4Sex] = useAtom(player4SexAtom);

  const [botLeftPlayer, setBotLeftPlayer] = useAtom(botLeftPlayerAtom);
  const [botRightPlayer, setBotRightPlayer] = useAtom(botRightPlayerAtom);
  const [topLeftPlayer, setTopLeftPlayer] = useAtom(topLeftPlayerAtom);
  const [topRightPlayer, setTopRightPlayer] = useAtom(topRightPlayerAtom);
  const [currentServerPlayer, setCurrentServerPlayer] = useAtom(
    currentServerPlayerAtom
  );

  const [youtubeLink, setYoutubeLink] = useAtom(youtubeLinkAtom);
  const [youtubeId, setYoutubeId] = useAtom(youtubeIdAtom);
  const [tournamentName, setTournamentName] = useAtom(tournamentNameAtom);
  const [tournamentYear, setTournamentYear] = useAtom(tournamentYearAtom);
  const [tournamentList, setTournamentList] = useAtom(tournamentListAtom);
  const [gameNumber, setGameNumber] = useAtom(gameNumberAtom);
  const [skillLevel, setSkillLevel] = useAtom(skillLevelAtom);
  const [detUserName, setDetUserName] = useAtom(detUserNameAtom);
  const [ballType, setBallType] = useAtom(ballTypeAtom);
  const [ballList, setBallList] = useAtom(ballListAtom);
  const [datePlayed, setDatePlayed] = useAtom(datePlayedAtom);

  // Game Data
  const [clicks, setClicks] = useAtom(clicksAtom);
  const [rallies, setRallies] = useAtom(ralliesAtom);
  const [pointNumber, setPointNumber] = useAtom(pointNumberAtom);
  const [shotNumber, setShotNumber] = useAtom(shotNumberAtom);
  const [elapsed, setElapsed] = useAtom(elapsedAtom);
  const [elapsedSeconds, setElapsedSeconds] = useAtom(elapsedSecondsAtom);
  const [servingTeam, setServingTeam] = useAtom(servingTeamAtom);
  const [teamAScore, setTeamAScore] = useAtom(teamAScoreAtom);
  const [teamBScore, setTeamBScore] = useAtom(teamBScoreAtom);
  const [serverNumber, setServerNumber] = useAtom(serverNumberAtom);
  const [inputModel, setInputModel] = useAtom(inputModelAtom);
  const [inputModels, setInputModels] = useAtom(inputModelsAtom);

  // Rally Data
  const [thirdShotPlayerSide, setThirdShotPlayerSide] = useAtom(
    thirdShotPlayerSideAtom
  );
  const [returnerSwitch, setReturnerSwitch] = useAtom(returnerSwitchAtom);
  const [serverSwitch, setServerSwitch] = useAtom(serverSwitchAtom);
  const [teamAOriginalPos] = useAtom(teamAOriginalPosAtom);
  const [teamBOriginalPos] = useAtom(teamBOriginalPosAtom);

  // UI Displays
  const [displayIntroModal, setDisplayIntroModal] = useAtom(
    displayIntroModalAtom
  );
  const [showMatchInfo, setShowMatchInfo] = useState(true);
  const [displayShotModal, setDisplayShotModal] = useState(false);

  // Instance
  const [youtubePlayer, setYoutubePlayer] = useState(null);
  const [gameBegun, setGameBegun] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState();

  // beginner
  const [currentShotPlayer, setCurrentShotPlayer] = useState("");
  const [currentShotType, setCurrentShotType] = useState("");
  const [currentShotOutcome, setCurrentShotOutcome] = useState("Continue Play");
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const API_HOST = process.env.REACT_APP_API_URL;

  // On Load -> Canvas Setup
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 650;
    canvas.height = 1200;
    canvas.style.width = `325px`;
    canvas.style.height = `600px`;
    const context = canvas.getContext("2d");

    context.fillStyle = "black";
    context.scale(2, 2);
    context.lineWidth = 3;
    contextRef.current = context;

    async function loadData() {
      if (playerList.length === 0) {
        let res = await fetch(`${API_HOST}/players/select_all`);
        let json = await res.json();
        setPlayerList(json.players);
      }

      if (ballList.length === 0) {
        let res = await fetch(`${API_HOST}/ball_types`);
        let json = await res.json();
        setBallList(json.ball_types);
      }

      if (tournamentList.length === 0) {
        let res = await fetch(`${API_HOST}/tournaments`);
        let json = await res.json();
        setTournamentList(json.tournaments);
      }
      setIsLoading(false);
    }

    loadData();
  }, []);

  useEffect(() => {
    if (rallies.length > 0) {
      const newRallies = [...rallies];
      const lastRally = newRallies[0];
      newRallies[0].score = getScore();
      setRallies(newRallies);
    }
  }, [`${teamAScore}:${teamBScore}:${serverNumber}`]);

  useEffect(() => {
    if (rallies.length === 0) {
      setCurrentServerPlayer(player1);
    }
  }, [player1]);

  /**
   * CANVAS FUNCTIONS
   */
  const recordClickShot = (event) => {
    event.preventDefault();

    if (!gameBegun) {
      alert("Please click the 'Begin Game' button above!");
      return;
    }

    const { offsetX, offsetY } = event.nativeEvent;
    let shotType = determineClickShotType(event);
    drawCircle(offsetX, offsetY, shotType);

    if (inputModel === "standard") {
      setClicks((current) => [
        {
          // shotId: shotId,
          pointNumber: pointNumber,
          shotNumber: shotNumber,
          shotPlayer: determineShotPlayer(offsetX, offsetY),
          shotType: shotType,
          shotSec: elapsedSeconds,
          videoTimestamp: elapsed,
          elapsedSec: elapsedSeconds,
          time: new Date().toISOString(),
          x: offsetX,
          y: offsetY,
        },
        ...current,
      ]);
      setShotNumber(shotNumber + 1);
    } else if (inputModel === "beginner") {
      console.log(`event: ${offsetX}, ${offsetY}`);
      setCurrentX(offsetX);
      setCurrentY(offsetY);
      youtubePlayer.pauseVideo();
      setDisplayShotModal(true);
    }
  };

  const finishRecordClickShot = () => {
    setClicks((current) => [
      {
        pointNumber: pointNumber,
        shotNumber: shotNumber,
        shotPlayer: currentShotPlayer,
        shotType: currentShotType,
        shotSec: elapsedSeconds,
        videoTimestamp: elapsed,
        elapsedSec: elapsedSeconds,
        time: new Date().toISOString(),
        x: currentX,
        y: currentY,
      },
      ...current,
    ]);
    setShotNumber(shotNumber + 1);
  };

  const determineClickShotType = (event) => {
    if (shotNumber === 1) {
      if (event.type === "contextmenu") {
        setServerSwitch(1);
      }
      return "SE";
    }

    if (shotNumber === 2) {
      if (event.type === "contextmenu") {
        setReturnerSwitch(1);
      }
      return "R";
    }

    if (event.type === "click" && shotNumber === 3) {
      if (event.shiftKey) {
        return "tsLob";
      } else {
        return "tsDrv";
      }
    }

    if (event.type === "contextmenu" && shotNumber === 3) return "tsDrp";

    if (event.type === "contextmenu") return "D";

    if (event.shiftKey) return "L";

    return "O";
  };

  const recordKey = (event) => {
    if (event.key === "1") {
      if (swapTeamSides) {
        if (servingTeam === "A") {
          setThirdShotPlayerSide("L");
        } // ServingTeam === "B"
        else {
          setThirdShotPlayerSide("R");
        }
      } else {
        if (servingTeam === "A") {
          setThirdShotPlayerSide("R");
        } // ServingTeam === "B"
        else {
          setThirdShotPlayerSide("L");
        }
      }
    } else if (event.key === "2") {
      if (swapTeamSides) {
        if (servingTeam === "B") {
          setThirdShotPlayerSide("L");
        } // servingTeam === "A"
        else {
          setThirdShotPlayerSide("R");
        }
      } else {
        if (servingTeam === "B") {
          setThirdShotPlayerSide("R");
        } // servingTeam === "A"
        else {
          setThirdShotPlayerSide("L");
        }
      }
    }
  };

  const drawCircle = (x, y, shotType) => {
    switch (shotType) {
      case "D":
        contextRef.current.strokeStyle = "#F7E6CD";
        break;
      case "tsDrp":
        contextRef.current.strokeStyle = "#3D85C4";
        break;
      case "tsDrv":
        contextRef.current.strokeStyle = "#D18323";
        break;
      default:
        contextRef.current.strokeStyle = "black";
    }
    contextRef.current.beginPath();
    contextRef.current.arc(x, y, 5, 0, Math.PI * 2);
    contextRef.current.stroke();
    contextRef.current.strokeStyle = "black";
  };

  const clearCanvas = () => {
    // Could save current canvas to a screenshot for future use
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
  };

  /**
   * GAME FUNCTIONS
   */
  const advancePlay = (winner) => {
    clearCanvas();

    switch (gameType) {
      case "STANDARD_DOUBLES": {
        advancePlayDoubles(winner);
        break;
      }
      case "MLP_DOUBLES_2023": {
        advancePlayMlpDoubles(winner);
        break;
      }
      case "MLP_DOUBLES_2023_NO_FREEZE": {
        advancePlayMlpDoublesNoFreeze(winner);
        break;
      }
      case "MLP_DOUBLES_2024": {
        advancePlayMlpDoubles2024(winner);
        break;
      }
      case "SINGLES": {
        advancePlaySingles(winner);
        break;
      }
    }

    // Reset and Advance
    setShotNumber(1);
    setPointNumber(pointNumber + 1);
    setThirdShotPlayerSide("N/A");
    setReturnerSwitch(0);
    setServerSwitch(0);
  };

  const advancePlayMlpDoubles = (winner) => {
    if (winner === "A") {
      if (servingTeam === "A") {
        setTeamAScore(teamAScore + 1);
      } else if (servingTeam === "B") {
        if (teamAScore < 20 && teamBScore < 20) {
          setTeamAScore(teamAScore + 1);
        } // SOMEONE IS AT 20
        else {
          // team b > 20 and team A < 20
          if (teamBScore >= 20 && teamAScore < 18) {
            setTeamAScore(teamAScore + 1);
          }
        }
      }

      if (servingTeam === "B") {
        setServingTeam("A");
      }
    } else if (winner === "B") {
      if (servingTeam === "B") {
        setTeamBScore(teamBScore + 1);
      } else if (servingTeam === "A") {
        if (teamBScore < 20 && teamAScore < 20) {
          setTeamBScore(teamBScore + 1);
        } // SOMEONE IS AT 20
        else {
          // team b > 20 and team A < 20
          if (teamAScore >= 20 && teamBScore < 18) {
            setTeamBScore(teamBScore + 1);
          }
        }
      }

      if (servingTeam === "A") {
        setServingTeam("B");
      }
    }
  };

  const advancePlayMlpDoublesNoFreeze = (winner) => {
    if (winner === "A") {
      if (servingTeam === "B" && teamAScore == 20 && teamBScore <= 19) {
      } else {
        setTeamAScore(teamAScore + 1);
      }

      if (servingTeam === "B") {
        setServingTeam("A");
      }
    } else if (winner === "B") {
      if (servingTeam === "A" && teamBScore == 20 && teamAScore <= 19) {
      } else {
        setTeamBScore(teamBScore + 1);
      }

      if (servingTeam === "A") {
        setServingTeam("B");
      }
    }
  };

  const advancePlayMlpDoubles2024 = (winner) => {
    if (winner === "A") {
      setTeamAScore(teamAScore + 1);

      if (servingTeam === "B") {
        setServingTeam("A");
      }
    } else if (winner === "B") {
      setTeamBScore(teamBScore + 1);

      if (servingTeam === "A") {
        setServingTeam("B");
      }
    }
  };

  const advancePlayDoubles = (winner) => {
    // TEAM A WON POINT
    if (winner === "A") {
      // Team A Scored
      if (servingTeam === "A") {
        setTeamAScore(teamAScore + 1);
      } // Team A Won Point but B Served
      else if (serverNumber === 2) {
        setServerNumber(1);
        setServingTeam("A");
      } else {
        // TEAM A won but Serving Team B and Server number is 1
        setServerNumber(serverNumber + 1);
      }
    } // TEAM B WON POINT
    else if (winner === "B") {
      // Team B Scored
      if (servingTeam === "B") {
        setTeamBScore(teamBScore + 1);
      } // Team B Won Point but A Served
      else if (serverNumber === 2) {
        setServerNumber(1);
        setServingTeam("B");
      } else {
        setServerNumber(serverNumber + 1);
      }
    }
  };

  const advancePlaySingles = () => {};

  const getScore = () => {
    switch (gameType) {
      case "STANDARD_DOUBLES": {
        return servingTeam === "A"
          ? `${teamAScore}:${teamBScore}:${serverNumber}`
          : `${teamBScore}:${teamAScore}:${serverNumber}`;
      }
      case "MLP_DOUBLES_2023": {
        return servingTeam === "A"
          ? `${teamAScore}:${teamBScore}`
          : `${teamBScore}:${teamAScore}`;
      }
      case "MLP_DOUBLES_2023_NO_FREEZE": {
        return servingTeam === "A"
          ? `${teamAScore}:${teamBScore}`
          : `${teamBScore}:${teamAScore}`;
      }
      case "MLP_DOUBLES_2024": {
        return servingTeam === "A"
          ? `${teamAScore}:${teamBScore}`
          : `${teamBScore}:${teamAScore}`;
      }
      case "SINGLES": {
        return `${teamAScore}:${teamBScore}`;
      }
    }
  };

  const updateShotPlayer = (shot, player) => {
    const nextClicks = clicks.map((click) => {
      if (
        shot.pointNumber == click.pointNumber &&
        shot.shotNumber == click.shotNumber
      ) {
        click["shotPlayer"] = player;
        return click;
      } else {
        return click;
      }
    });
    setClicks(nextClicks);
  };

  const endRally = (pointOutcome, endingPlayer, endingType) => {
    if (!gameBegun) {
      alert("Please click the 'Begin Game' button above!");
      return;
    }

    let shotPoints = clicks.filter(
      (click) => click.pointNumber === pointNumber
    );
    let firstPoint = shotPoints.at(-1);
    let secondToLastPoint = shotPoints.at(1);
    let lastPoint = shotPoints.at(0);

    // 1) Apply the "Last click" logic
    // 2) IF shot #1 isn't already populated with a player, figure out who the server was
    //     This will require referencing the stacking indicator
    //     We should not be using the loc_x information, and instead should be able to deduce the server based on the score and everyone's starting position
    // 3) IF shot #2 isn't already populated with a player (or NA), figure out who the returner was
    //     We should not be using the loc_x information, and instead should be able to deduce the returner based on the score and everyone's starting position
    // 4) IF shot #3 isn't already populated with a player  (or NA), figure out who hit the third shot
    //     This logic will need to reference the 1/2 hotkey inputs. If 1/2 hotkeys are not used,
    //     then player should be left blank.

    if (["Unforced Error", "Error", "Winner"].includes(endingType)) {
      let returningTeamPlayers;
      let servingTeamPlayers;

      if (servingTeam === "A") {
        servingTeamPlayers = [player1.name, player2.name];
        returningTeamPlayers = [player3.name, player4.name];
      } else {
        returningTeamPlayers = [player1.name, player2.name];
        servingTeamPlayers = [player3.name, player4.name];
      }

      if (
        shotPoints.length % 2 === 0 &&
        servingTeamPlayers.includes(endingPlayer)
      ) {
        updateShotPlayer(lastPoint, "N/A");
        const nextClicks = clicks.map((click) => {
          if (
            click.pointNumber == lastPoint.pointNumber &&
            click.shotNumber == lastPoint.shotNumber
          ) {
            click["shotType"] = "ball";
            return click;
          } else {
            return click;
          }
        });
        setClicks(nextClicks);

        if (shotPoints.length > 1) {
          updateShotPlayer(secondToLastPoint, endingPlayer);
        }
      } else if (
        shotPoints.length % 2 === 1 &&
        returningTeamPlayers.includes(endingPlayer)
      ) {
        updateShotPlayer(lastPoint, "N/A");
        const nextClicks = clicks.map((click) => {
          if (
            click.pointNumber == lastPoint.pointNumber &&
            click.shotNumber == lastPoint.shotNumber
          ) {
            click["shotType"] = "ball";
            return click;
          } else {
            return click;
          }
        });
        setClicks(nextClicks);

        if (shotPoints.length > 1) {
          updateShotPlayer(secondToLastPoint, endingPlayer);
        }
      } else if (
        shotPoints.length % 2 === 0 &&
        returningTeamPlayers.includes(endingPlayer)
      ) {
        updateShotPlayer(lastPoint, endingPlayer);
      } else if (
        shotPoints.length % 2 === 1 &&
        servingTeamPlayers.includes(endingPlayer)
      ) {
        updateShotPlayer(lastPoint, endingPlayer);
      }
    }

    setRallies((current) => [
      {
        thisPointNumber: pointNumber,
        pointOutcome,
        servingTeamId: servingTeam,
        score: getScore(),
        start: firstPoint && firstPoint.videoTimestamp,
        startSec: firstPoint && firstPoint.elapsedSec,
        end: lastPoint && lastPoint.videoTimestamp,
        endSec: lastPoint && lastPoint.elapsedSec,
        rallyLen: calculateCurrentRallyLength(),
        returnerSwitchInd: returnerSwitch,
        serverSwitchInd: serverSwitch,
        thirdShotPlayerSide,
        thirdShotType: determineThirdShotType(),
        endingPlayer,
        endingType,
        dinkCount: calculateDinks(),
        lobCount: calculateLobs(),
        teamAOriginalPosInd: Number(teamAOriginalPos),
        teamBOriginalPosInd: Number(teamBOriginalPos),
      },
      ...current,
    ]);

    advancePlay(pointOutcome);
  };

  const determineShotPlayerBeginner = (x, y) => {};

  const determineShotPlayer = (x, y) => {
    if ([1, 2].includes(shotNumber)) {
      if (y < 300) {
        if (x < 163) {
          return topLeftPlayer.name;
        } else {
          return topRightPlayer.name;
        }
      } else {
        if (x < 163) {
          return botLeftPlayer.name;
        } else {
          return botRightPlayer.name;
        }
      }
    } else if (shotNumber === 3) {
      if (swapTeamSides) {
        if (thirdShotPlayerSide === "L" && servingTeam === "A") {
          return botLeftPlayer.name;
        } else if (thirdShotPlayerSide === "R" && servingTeam === "A") {
          return botRightPlayer.name;
        } else if (thirdShotPlayerSide === "L" && servingTeam === "B") {
          return topRightPlayer.name;
        } else if (thirdShotPlayerSide === "R" && servingTeam === "B") {
          return topLeftPlayer.name;
        }
      } else {
        if (thirdShotPlayerSide === "L" && servingTeam === "A") {
          return topRightPlayer.name;
        } else if (thirdShotPlayerSide === "R" && servingTeam === "A") {
          return topLeftPlayer.name;
        } else if (thirdShotPlayerSide === "L" && servingTeam === "B") {
          return botLeftPlayer.name;
        } else if (thirdShotPlayerSide === "R" && servingTeam === "B") {
          return botRightPlayer.name;
        }
      }
    }
  };

  const determineThirdShotType = () => {
    const shot =
      clicks.find(
        (click) => click.pointNumber === pointNumber && click.shotNumber === 3
      )?.shotType || "N/A";

    return formatTs(shot);
  };

  const calculateCurrentRallyLength = () => {
    return clicks.filter((click) => click.pointNumber === pointNumber).length;
  };

  const calculateDinks = () => {
    return clicks.filter(
      (click) => click.pointNumber === pointNumber && click.shotType === "D"
    ).length;
  };

  const calculateLobs = () => {
    return clicks.filter(
      (click) =>
        click.pointNumber === pointNumber &&
        (click.shotType === "L" || click.shotType === "tsL")
    ).length;
  };

  const setYoutubeLinkAndYouTubeId = (value) => {
    setYoutubeLink(value);
    // https://www.youtube.com/watch?v=lwIV1ia63vM
    const urlParams = new URLSearchParams(value.split("?")[1]);
    const id = urlParams.get("v");
    if (id?.length === 11) {
      setYoutubeId(id);
    }
  };

  const testData = () => {
    setYoutubeLinkAndYouTubeId("https://www.youtube.com/watch?v=5ZH4MqPOZVI");
    setTournamentName("PPA Vegas Championships");
    setTournamentYear("2022");
    setGameNumber("1");
    setPlayer1({ ...player1, name: "Anna Leigh Waters" });
    setPlayer2({ ...player2, name: "Ben Johns" });
    setPlayer3({ ...player3, name: "JW Johnson" });
    setPlayer4({ ...player4, name: "Catherine Parenteau" });
    setSkillLevel("Pro");
    setDetUserName("det_webapp");
  };

  const beginMatch = () => {
    // validations
    if (
      !/\w+\ \w+/.test(player1.name) ||
      !/\w+\ \w+/.test(player2.name) ||
      !/\w+\ \w+/.test(player3.name) ||
      !/\w+\ \w+/.test(player4.name)
    ) {
      alert(
        "Please enter valid player names for all players. Format: 'First Last'"
      );
      return;
    }

    if (detUserName === "") {
      alert(
        "Please enter a value for DET Username. (Any name so we can give you credit)"
      );
      return;
    }

    if (!datePlayed) {
      alert("Please the date (approximately) of when the game was played.");
      return;
    }

    if (ballType?.value == "") {
      alert("Please select a ball type.");
      return;
    }

    setShowMatchInfo(false);
    setGameBegun(true);

    if (elapsedSeconds > 0) {
      youtubePlayer?.seekTo(elapsedSeconds, "seconds");
    }

    const interval = setInterval(async () => {
      const elapsed_sec =
        await videoRef.current.internalPlayer.getCurrentTime();
      // calculations
      const elapsed_ms = Math.floor(elapsed_sec * 1000);
      const ms = elapsed_ms % 1000;
      const min = Math.floor(elapsed_ms / 60000);
      const seconds = Math.floor((elapsed_ms - min * 60000) / 1000);

      setElapsedSeconds(elapsed_sec);
      setElapsed(
        min.toString().padStart(2, "0") +
          ":" +
          seconds.toString().padStart(2, "0") +
          ":" +
          ms.toString().padStart(3, "0")
      );
    }, 100); // 100 ms refresh. increase it if you don't require millisecond precision
  };

  const updateRally = (ptNbr, field) => {
    return (e) => {
      const rally = rallies.find((rally) => rally.thisPointNumber === ptNbr);
      rally[field] = e.target.value;

      if (field === "thirdShotType") {
        setClicks(
          clicks.map((click) => {
            if (click.pointNumber === ptNbr && click.shotNumber === 3) {
              click.shotType = formatTsClick(e.target.value);
            }
            return click;
          })
        );
      }

      rally.lobCount = clicks.filter(
        (click) =>
          click.pointNumber === ptNbr &&
          (click.shotType === "L" || click.shotType === "Lob")
      ).length;
      rally.dinkCount = clicks.filter(
        (click) => click.pointNumber === ptNbr && click.shotType === "D"
      ).length;

      const newRallies = [...rallies];
      newRallies[newRallies.length - ptNbr] = rally;
      setRallies(newRallies);
    };
  };

  const updateShot = (ptNbr, shotNumber, field) => {
    return (e) => {
      const nextClicks = clicks.map((click) => {
        if (click.pointNumber == ptNbr && click.shotNumber == shotNumber) {
          click[field] = e.target.value;
          return click;
        } else {
          return click;
        }
      });
      setClicks(nextClicks);
    };
  };

  const downloadInfoCsv = () => {
    const rows = [
      [
        "tourn_name",
        "tourn_yr",
        "consol_ind",
        "game_nbr",
        "vod_url",
        "player_a1",
        "player_a2",
        "player_b1",
        "player_b2",
        "skill_level",
        "det_user",
        "scoring_type",
        "date_played",
      ],
    ];
    rows.push([
      tournamentName,
      tournamentYear,
      0,
      gameNumber,
      youtubeLink,
      player1.name,
      player2.name,
      player4.name, // swap b1 and b2 2/2/2023
      player3.name,
      skillLevel,
      detUserName,
      gameType,
      datePlayed,
    ]);
    createAndDownloadCsv(rows, `${csvRootName()}_info`);
  };

  const downloadShotLogCsv = () => {
    const rows = [
      [
        "pt_nbr",
        "shot_nbr",
        "player_nm",
        "shot_type",
        "input_timestamp",
        "youtube_timestamp",
        "mouse_x_coord",
        "mouse_y_coord",
        "window_l",
        "window_w",
        "corner_x",
        "corner_y",
      ],
    ];
    clicks.forEach(
      ({
        pointNumber,
        shotNumber,
        shotPlayer,
        shotType,
        time,
        videoTimestamp,
        x,
        y,
      }) => {
        rows.push([
          pointNumber,
          shotNumber,
          shotPlayer,
          shotType,
          time,
          videoTimestamp,
          x,
          y,
          600,
          325,
          0,
          0,
        ]);
      }
    );
    createAndDownloadCsv(rows, `${csvRootName()}_shots`);
  };

  const formatTsClick = (thirdShotRallyType) => {
    if (thirdShotRallyType === "Drive") {
      return "tsDrv";
    } else if (thirdShotRallyType === "Drop") {
      return "tsDrp";
    } else if (thirdShotRallyType === "Lob") {
      return "tsLob";
    }
    return "";
  };

  const formatTs = (thirdShotType) => {
    if (thirdShotType === "tsDrv") {
      return "Drive";
    } else if (thirdShotType === "tsDrp") {
      return "Drop";
    } else if (thirdShotType === "tsLob") {
      return "Lob";
    }
    return "";
  };

  const downloadRallyLogCsv = () => {
    const rows = [
      [
        "pt_nbr",
        "pt_outcome",
        "serving_team_id",
        "rally_len",
        "server_switch_ind",
        "returner_switch_ind",
        "third_shot_player_side",
        "third_shot_type",
        "ending_player",
        "ending_type",
        "lob_cnt",
        "dink_cnt",
        "team_a_original_pos",
        "team_b_original_pos",
      ],
    ];
    rallies.forEach(
      ({
        thisPointNumber,
        pointOutcome,
        servingTeamId,
        rallyLen,
        serverSwitchInd,
        returnerSwitchInd,
        thirdShotPlayerSide,
        thirdShotType,
        endingPlayer,
        endingType,
        lobCount,
        dinkCount,
        teamAOriginalPosInd,
        teamBOriginalPosInd,
      }) => {
        rows.push([
          thisPointNumber,
          pointOutcome,
          servingTeamId,
          rallyLen,
          serverSwitchInd,
          returnerSwitchInd,
          thirdShotPlayerSide,
          // formatTs(thirdShotType),
          thirdShotType,
          endingPlayer,
          endingType,
          lobCount,
          dinkCount,
          gameType === "MLP_DOUBLES_2023" ||
          gameType === "MLP_DOUBLES_2023_NO_FREEZE" ||
          gameType === "MLP_DOUBLES_2024"
            ? teamAOriginalPosInd
            : "",
          gameType === "MLP_DOUBLES_2023" ||
          gameType === "MLP_DOUBLES_2023_NO_FREEZE" ||
          gameType === "MLP_DOUBLES_2024"
            ? teamBOriginalPosInd
            : "",
        ]);
      }
    );
    createAndDownloadCsv(rows, `${csvRootName()}_rally_log`);
  };

  const createAndDownloadCsv = (rows, name) => {
    let csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((row) => row.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const downloadCsvs = () => {
    downloadInfoCsv();
    downloadShotLogCsv();
    downloadRallyLogCsv();
  };

  const csvRootName = () => {
    return (
      `${tournamentName.toLowerCase().split(" ").join("_")}_` +
      `${tournamentYear}_${player1.name.toLowerCase().split(" ").join("_")}` +
      `${player2.name.toLowerCase().split(" ").join("_")}_` +
      `${player3.name.toLowerCase().split(" ").join("_")}` +
      `${player4.name.toLowerCase().split(" ").join("_")}_${gameNumber}`
    );
  };

  const clearGameData = async () => {
    Object.keys(storeDefault).forEach((key) => localStorage.removeItem(key));
    window.location.reload();
  };

  const insertData = async () => {
    setIsLoading(true);
    setLoadingText("Data is being sent to pklmart servers...");

    try {
      let res = await fetch(`${API_HOST}/raw_games`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          tournamentName,
          tournamentYear,
          gameNumber,
          youtubeLink,
          player1: player1.name,
          player1Handedness: player1Handedness.value,
          player1Sex: player1Sex.value,
          player2: player2.name,
          player2Handedness: player2Handedness.value,
          player2Sex: player2Sex.value,
          player4: player4.name, // swap b1 and b2 2/2/2023
          player4Handedness: player4Handedness.value,
          player4Sex: player4Sex.value,
          player3: player3.name,
          player3Handedness: player3Handedness.value,
          player3Sex: player3Sex.value,
          skillLevel,
          detUserName,
          gameType,
          ballType: ballType?.value,
          datePlayed: datePlayed,
          rallies,
          clicks,
        }),
      });

      let json = await res.json();

      setIsLoading(false);
      setLoadingText(null);

      if (res.status == 200) {
        await alert(
          "Well done! 🥳 \n\nTo retrieve your match report please reach out to pklmart.analytics@gmail.com with the subject line 'Create Match Report'. You do not need to provide the downloaded .CSV files."
        );
      } else {
        await alert(
          "Something went wrong! 🚨 \n\nPlease email pklmart.analytics@gmail.com with the CSVs you downloaded. Thank you."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearAllRallies = () => {
    setRallies([]);
    setClicks([]);
    setPointNumber(1);
    setShotNumber(1);
    setTeamAScore(0);
    setTeamBScore(0);
    setServingTeam("A");
    setServerNumber(2);
    setThirdShotPlayerSide("N/A");
    setReturnerSwitch(0);
    setServerSwitch(0);
    clearCanvas();
  };

  // if error -> assign last

  return (
    <>
      <div className="mx-auto h-full">
        <Header />
        <div className="p-4 border-b-2" id="det-game-info">
          <h2
            className={classNames(
              "cursor-pointer text-xl font-bold flex items-center"
            )}
            onClick={() => setShowMatchInfo(!showMatchInfo)}
          >
            <svg
              className={classNames("h-4 w-4 transform mr-2", {
                "-rotate-90": !showMatchInfo,
                "rotate-0": showMatchInfo,
              })}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
            Game Info{" "}
            {tournamentName && `- ${tournamentName} ${tournamentYear}`}
          </h2>
          {showMatchInfo && (
            <div id="det-game-info-inputs-container" className="px-4 pb-4">
              <div className="pt-4 2xl:flex">
                <div id="det-game-info-inputs">
                  <div className="flex mb-4">
                    <div className="mr-2">
                      <Tooltip
                        content="The Youtube URL as it appears in your internet browser. Do NOT use the URL provided by Youtube's 'Share' feature"
                        placement="right-start"
                      >
                        <label className="inline-block text-sm font-medium text-gray-700">
                          Video URL*
                        </label>
                      </Tooltip>
                      <input
                        name="videoUrl"
                        className="p-2 block rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-96"
                        onChange={(e) =>
                          setYoutubeLinkAndYouTubeId(e.target.value)
                        }
                        value={youtubeLink}
                      />
                    </div>
                    <div className="mr-2">
                      <label className="inline-block text-sm font-medium text-gray-700">
                        Game Type
                      </label>
                      <select
                        value={gameType}
                        onChange={(e) => {
                          if (rallies.length > 0) {
                            if (
                              window.confirm(
                                "Are sure you want to change the game type?\n\nChanging the game type in the middle of a game will clear all rallies and shots!\n\nThis action is irreverisble."
                              )
                            ) {
                              clearAllRallies();
                              setGameType(e.target.value);
                            }
                          } else {
                            setGameType(e.target.value);
                          }
                        }}
                        id="gameType"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option value="STANDARD_DOUBLES">
                          Standard Doubles
                        </option>
                        <option value="MLP_DOUBLES_2023">MLP Rally 2023</option>
                        <option value="MLP_DOUBLES_2023_NO_FREEZE">
                          MLP Rally 2023 (No Freeze)
                        </option>
                        <option value="MLP_DOUBLES_2024">MLP Rally 2024</option>

                        <option disabled={true} value="SINGLES">
                          Singles (Coming Soon!)
                        </option>
                      </select>
                    </div>
                    {/*
                  <div>
                    <label className="inline-block text-sm font-medium text-gray-700">Input Type</label>
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="inputType"
                      value={inputModel}
                      onChange={(e) => setInputModel(e.target.value)}
                    >
                      {Object.entries(inputModels).map(([inputName, inputValue], _) =>
                        <option key={inputName} value={inputValue}>
                          {inputName}
                        </option>)}
                    </select>
                  </div>
                  */}
                  </div>
                  <div className="matchInfo flex mb-4">
                    <div className="mr-2">
                      <Tooltip
                        content="If a game is not part of a tournament, enter a brief description (e.g. Rec Play - Pickleball Center Jan 23)"
                        placement="right-start"
                      >
                        <label className="inline-block text-sm font-medium text-gray-700">
                          Tournament Name*
                        </label>
                      </Tooltip>
                      <CreatableSelect
                        className="w-96"
                        isClearable
                        options={tournamentList}
                        onChange={(obj) => {
                          setTournamentName(obj?.value);
                        }}
                        value={{ label: tournamentName, value: tournamentName }}
                      />
                    </div>
                    <div className="mr-2">
                      <Tooltip
                        content="If a game is not part of a tournament, enter the year the game was played"
                        placement="right-start"
                      >
                        <label className="inline-block text-sm font-medium text-gray-700">
                          Tournament Year*
                        </label>
                      </Tooltip>
                      <input
                        name="tournamentYear"
                        className="p-2 block rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-64"
                        onChange={(e) => setTournamentYear(e.target.value)}
                        value={tournamentYear}
                      />
                    </div>
                    <div className="mr-2">
                      <Tooltip
                        content="If entering a match consisting of multiple games, enter the game being entered. Otherwise enter 1."
                        placement="right-start"
                      >
                        <label className="inline-block text-sm font-medium text-gray-700">
                          Game Number*
                        </label>
                      </Tooltip>
                      <input
                        type="number"
                        min="1"
                        max="7"
                        name="gameNumber"
                        className="p-2 block rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-32"
                        onChange={(e) => setGameNumber(e.target.value)}
                        onBlur={(e) => {
                          if (e.target.value > 7 || e.target.value < 1) {
                            setGameNumber(1);
                          }
                        }}
                        value={gameNumber}
                      />
                    </div>
                    <div className="">
                      <Tooltip
                        content="Approximate date on when the game was played"
                        placement="right-start"
                      >
                        <label className="inline-block text-sm font-medium text-gray-700">
                          Date Played*
                        </label>
                      </Tooltip>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="override-mui-datepicker"
                          value={dayjs(datePlayed)}
                          onChange={(value) => {
                            setDatePlayed(value);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="playerNames flex mb-4">
                    <div className="mr-2">
                      <div className="group">
                        <label className="block text-sm font-medium text-gray-700">
                          Player 1A (Starting Server)*
                        </label>
                        <p className="text-sm break-words w-96 tooltip-text hidden text-center py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          Input full players' names -- this will allow us to
                          aggregate a player's performance over several of
                          games/matches
                        </p>
                      </div>
                      <div className="mt-1">
                        <CreatableSelect
                          className="w-64"
                          isClearable
                          options={playerList}
                          onChange={(obj) => {
                            setPlayer1({ ...player1, name: obj?.value });
                          }}
                          value={{ label: player1.name, value: player1.name }}
                        />
                      </div>
                      <div className="mt-2 flex text-xs">
                        <div>
                          <label className="block font-medium text-gray-700">
                            Handedness
                          </label>
                          <Select
                            options={[
                              { label: "Right", value: "R" },
                              { label: "Left", value: "L" },
                            ]}
                            onChange={(obj) => setPlayer1Handedness(obj)}
                            value={player1Handedness}
                          />
                        </div>
                        <div className="ml-2">
                          <label className="block font-medium text-gray-700">
                            Sex
                          </label>
                          <Select
                            options={[
                              { label: "Male", value: "M" },
                              { label: "Female", value: "F" },
                              { label: "Other", value: "O" },
                            ]}
                            onChange={(obj) => setPlayer1Sex(obj)}
                            value={player1Sex}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mr-2">
                      <div className="group">
                        <label className="block text-sm font-medium text-gray-700">
                          Player 2A*
                        </label>
                        <p className="text-sm break-words w-96 tooltip-text hidden text-center py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          Input full players' names -- this will allow us to
                          aggregate a player's performance over several of
                          games/matches
                        </p>
                      </div>
                      <div className="mt-1">
                        <CreatableSelect
                          className="w-64"
                          isClearable
                          options={playerList}
                          onChange={(obj) => {
                            setPlayer2({ ...player2, name: obj?.value });
                          }}
                          value={{ label: player2.name, value: player2.name }}
                        />
                      </div>
                      <div className="mt-2 flex text-xs">
                        <div>
                          <label className="block font-medium text-gray-700">
                            Handedness
                          </label>
                          <Select
                            options={[
                              { label: "Right", value: "R" },
                              { label: "Left", value: "L" },
                            ]}
                            onChange={(obj) => setPlayer2Handedness(obj)}
                            value={player2Handedness}
                          />
                        </div>
                        <div className="ml-2">
                          <label className="block font-medium text-gray-700">
                            Sex
                          </label>
                          <Select
                            options={[
                              { label: "Male", value: "M" },
                              { label: "Female", value: "F" },
                              { label: "Other", value: "O" },
                            ]}
                            onChange={(obj) => setPlayer2Sex(obj)}
                            value={player2Sex}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mr-2">
                      <div className="group">
                        <label className="block text-sm font-medium text-gray-700">
                          Player 3B*
                        </label>
                        <p className="text-sm break-words w-96 tooltip-text hidden text-center py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          Input full players' names -- this will allow us to
                          aggregate a player's performance over several of
                          games/matches
                        </p>
                      </div>
                      <div className="mt-1">
                        <CreatableSelect
                          className="w-64"
                          isClearable
                          options={playerList}
                          onChange={(obj) => {
                            setPlayer3({ ...player3, name: obj?.value });
                          }}
                          value={{ label: player3.name, value: player3.name }}
                        />
                      </div>
                      <div className="mt-2 flex text-xs">
                        <div>
                          <label className="block font-medium text-gray-700">
                            Handedness
                          </label>
                          <Select
                            options={[
                              { label: "Right", value: "R" },
                              { label: "Left", value: "L" },
                            ]}
                            onChange={(obj) => setPlayer3Handedness(obj)}
                            value={player3Handedness}
                          />
                        </div>
                        <div className="ml-2">
                          <label className="block font-medium text-gray-700">
                            Sex
                          </label>
                          <Select
                            options={[
                              { label: "Male", value: "M" },
                              { label: "Female", value: "F" },
                              { label: "Other", value: "O" },
                            ]}
                            onChange={(obj) => setPlayer3Sex(obj)}
                            value={player3Sex}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mr-2">
                      <div className="group">
                        <label className="block text-sm font-medium text-gray-700">
                          Player 4B (Starting Returner)*
                        </label>
                        <p className="text-sm break-words w-96 tooltip-text hidden text-center py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          Input full players' names -- this will allow us to
                          aggregate a player's performance over several of
                          games/matches
                        </p>
                      </div>
                      <div className="mt-1">
                        <CreatableSelect
                          className="w-64"
                          isClearable
                          options={playerList}
                          onChange={(obj) => {
                            setPlayer4({ ...player4, name: obj?.value });
                          }}
                          value={{ label: player4.name, value: player4.name }}
                        />
                      </div>
                      <div className="mt-2 flex text-xs">
                        <div>
                          <label className="block font-medium text-gray-700">
                            Handedness
                          </label>
                          <Select
                            options={[
                              { label: "Right", value: "R" },
                              { label: "Left", value: "L" },
                            ]}
                            onChange={(obj) => setPlayer4Handedness(obj)}
                            value={player4Handedness}
                          />
                        </div>
                        <div className="ml-2">
                          <label className="block font-medium text-gray-700">
                            Sex
                          </label>
                          <Select
                            options={[
                              { label: "Male", value: "M" },
                              { label: "Female", value: "F" },
                              { label: "Other", value: "O" },
                            ]}
                            onChange={(obj) => setPlayer4Sex(obj)}
                            value={player4Sex}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-64 mb-4 mr-2">
                      <label
                        htmlFor="skill"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Skill Level*
                      </label>
                      <select
                        value={skillLevel}
                        onChange={(e) => setSkillLevel(e.target.value)}
                        id="skill"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option>Choose a skill level for this game</option>
                        <option value="0.0">0.0</option>
                        <option value="2.5">2.5</option>
                        <option value="3.0">3.0</option>
                        <option value="3.5">3.5</option>
                        <option value="4.0">4.0</option>
                        <option value="4.5">4.5</option>
                        <option value="5.0">5.0</option>
                        <option value="5.5">5.5</option>
                        <option value="Pro">Pro</option>
                        <option value="Senior Pro">Senior Pro</option>
                      </select>
                    </div>
                    <div className="mr-2">
                      <div className="group">
                        <label className="inline-block text-sm font-medium text-gray-700">
                          DET Username
                        </label>
                        <p className="text-sm break-words w-96 tooltip-text hidden text-center py-2 px-6 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          Free form field used to give individual performing
                          data entry (i.e. you!)
                        </p>
                      </div>
                      <div className="mt-1">
                        <input
                          name="player4"
                          className="p-2 block rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          onChange={(e) => setDetUserName(e.target.value)}
                          value={detUserName}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Ball Type
                      </label>
                      <div className="mt-2">
                        <Select
                          className="w-48"
                          options={ballList}
                          onChange={(obj) => setBallType(obj)}
                          value={ballType}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="det-instructions-and-tips"
                  className="2xl:ml-8 ml-0 2xl:block flex items-start"
                >
                  <div className="2xl:mb-4 inline-block">
                    <div
                      className={classNames(
                        "border p-4 rounded-lg cursor-pointer mr-2 hover:bg-gray-100",
                        { "hover:bg-blue-200": inputStyle === "standard" }
                      )}
                      onClick={() => {
                        setInputStyle("standard");
                        setDisplayIntroModal(!displayIntroModal);
                      }}
                    >
                      <p className="font-bold text-lg text-center mb-4">
                        Shot Entry Instructions
                      </p>
                      <div className="flex">
                        <div className="mr-4">
                          <p className="text-sm flex items-center mb-1">
                            <LeftMouseIcon className="h-5 w-5" />
                            <strong>Left Click</strong>
                          </p>
                          <p className="text-xs ml-5">
                            All shots not covered by Right Click
                          </p>
                          <p className="text-sm flex items-center font-bold mt-4 mb-1">
                            <RightMouseIcon className="h-5 w-5" />
                            Right-Click
                          </p>
                          <p className="text-xs ml-5">
                            Serve (If serving team stacked)
                          </p>
                          <p className="text-xs ml-5">
                            Return (If returning team stacked)
                          </p>
                          <p className="text-xs ml-5">Third Shot Drop</p>
                          <p className="text-xs ml-5">Dink</p>
                        </div>
                        <div>
                          <p className="text-sm flex items-center font-bold mb-1">
                            <LeftMouseIcon className="h-5 w-5" />
                            Shift + Left-Click
                          </p>
                          <p className="text-xs ml-5">Third Shot Lob</p>
                          <p className="text-xs ml-5">Lob</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="det-notes" className="2xl:ml-0 ml-6">
                    <ul className="text-sm">
                      <li>
                        Questions or bugs? Reach out to{" "}
                        <a
                          className="text-blue-400 font-bold"
                          href="mailto:pklmart.analytics@gmail.com"
                        >
                          pklmart.analytics@gmail.com
                        </a>
                        !
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="det-start-button" className="2xl:mt-0 mt-6 flex">
                <button
                  className="bg-green-700 hover:bg-green-500 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center"
                  disabled={!youtubeId || gameBegun}
                  onClick={() => {
                    beginMatch();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  {elapsedSeconds > 0 ? `Resume Game` : `Begin Game`}
                </button>
                {!youtubeId && (
                  <button
                    className="text-sm ml-2 rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline flex items-center"
                    onClick={testData}
                  >
                    Example Fill
                  </button>
                )}
                <button
                  className="text-sm font-semibold ml-2 text-white enabled:bg-red-400 enabled:hover:bg-red-600 rounded-md bg-white px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are sure you want to clear the current game's data? This includes all rally/shot info. This action is irreversible!"
                      )
                    )
                      clearGameData();
                  }}
                >
                  💣 Clear Game Data
                </button>
                <button
                  className="text-sm rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline flex items-center ml-2"
                  onClick={() => setShowMatchInfo(false)}
                >
                  Hide Game Info
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="" id="det-video-court-tools-container">
          <DataEntryToolHeader
            currentScore={getScore()}
            servingTeam={servingTeam}
            teamAScore={teamAScore}
            teamBScore={teamBScore}
            serverNumber={serverNumber}
            gameType={gameType}
            elapsed={elapsed}
          />
          <div className="flex" id="det-video-court-tools">
            <LeftSideVideoPlayer
              youtubeId={youtubeId}
              videoRef={videoRef}
              setYoutubePlayer={setYoutubePlayer}
              youtubePlayer={youtubePlayer}
              lastRallySeconds={rallies[0]?.endSec}
              lastShotSeconds={clicks[0]?.elapsedSec}
            />
            <div className="w-1/2 flex" id="court-canvas" ref={ccRef}>
              <div className="flex flex-col relative">
                <div className="text-center bg-green-100/50 font-bold p-1 mb-1 text-sm">
                  💡Click where contact is being made!💡
                </div>
                <TopTeam endRally={endRally} />
                <canvas
                  tabIndex="-1"
                  style={{
                    background: `url(${court}) no-repeat center`,
                    backgroundSize: "contain",
                  }}
                  id="canvas"
                  className="mx-auto"
                  ref={canvasRef}
                  onClick={recordClickShot}
                  onContextMenu={recordClickShot}
                  onKeyDown={recordKey}
                />
                <BottomTeam endRally={endRally} />
                {displayShotModal && (
                  <ShotModal
                    currentShotPlayer={currentShotPlayer}
                    setCurrentShotPlayer={setCurrentShotPlayer}
                    currentShotType={currentShotType}
                    setCurrentShotType={setCurrentShotType}
                    currentShotOutcome={currentShotOutcome}
                    setCurrentShotOutcome={currentShotOutcome}
                    currentX={currentX}
                    currentY={currentY}
                    youtubePlayer={youtubePlayer}
                    endRally={endRally}
                    setDisplayShotModal={setDisplayShotModal}
                    finishRecordClickShot={finishRecordClickShot}
                  />
                )}
              </div>
              <RightSideTools
                downloadCsvs={downloadCsvs}
                clearGameData={clearGameData}
                insertData={insertData}
                clearAllRallies={clearAllRallies}
                clearCanvas={clearCanvas}
                youtubePlayer={youtubePlayer}
                drawCircle={drawCircle}
                updateShot={updateShot}
              />
            </div>
          </div>
        </div>
        <RallyAndShotLog
          rallies={rallies}
          youtubePlayer={youtubePlayer}
          clicks={clicks}
          updateRally={updateRally}
          updateShot={updateShot}
          player1={player1}
          player2={player2}
          player3={player3}
          player4={player4}
          currentPointNumber={pointNumber}
        />
      </div>
      <IntroModal
        displayIntroModal={displayIntroModal}
        setDisplayIntroModal={setDisplayIntroModal}
      />
      <Loading isLoading={isLoading} text={loadingText} />
    </>
  );
}

export default Det;
