import { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation } from "wouter";

import volley from "./../../assets/volley.png";
import dink from "./../../assets/dink.png";
import trans from "./../../assets/trans.png";
import serve from "./../../assets/serve.png";
import drop from "./../../assets/drop.png";
import returnImage from "./../../assets/return.png";
import repel from "./../../assets/repel.png";
import drive from "./../../assets/drive.png";

export default function UnreturnedRates({
  playerId,
  overAllUnreturnedPercent,
  serveUnreturnedPercent,
  returnUnreturnedPercent,
  thirdShotDriveUnreturnedPercent,
  thirdShotDropUnreturnedPercent,
  transitionZoneApproachUnreturnedPercent,
  transitionRepelUnreturnedPercent,
  dinkUnreturnedPercent,
  spUnreturnedPercent,
  unreturnedRates,
}) {
  const [location, setLocation] = useLocation();
  const [benchmark, setBenchmark] = useState("3.5");

  const comps = unreturnedRates.find((rates) => rates.id == benchmark);

  const errorRateWord = (comparedTo, comparePercent) => {
    const percent = ((comparePercent - comparedTo) * 100).toFixed(2);
    return (
      <>
        <span>
          &nbsp;
          <span
            className={classNames({
              "text-red-600": percent < 0,
              "text-[#18BC9C]": percent > 0,
            })}>
            {percent < 0 ? "Worse" : "Better"}
          </span>{" "}
          than other a typical&nbsp;
          {benchmark}:{" "}
          <span
            className={classNames({
              "text-red-600": percent < 0,
              "text-[#18BC9C]": percent > 0,
            })}>
            {(comparedTo * 100).toFixed(2)}%
          </span>
        </span>
      </>
    );
  };

  return (
    <>
      <div className='flex flex-col md:columns-2'>
        <div className='text-center my-4 flex flex-col items-center'>
          Compare your stats to
          <span className='isolate inline-flex ml-4 space-x-2'>
            <button
              type='button'
              className={classNames(
                "relative inline-flex items-center rounded-[0.15rem] px-2 py-1 tracking-wide text-md font-avenirmedium text-gray-900 focus:z-10",
                {
                  "bg-pkl-500 text-white": benchmark == "3.0",
                  "bg-gray-300 hover:bg-pkl-100": benchmark !== "3.0",
                }
              )}
              onClick={() => setBenchmark("3.0")}>
              3.0
            </button>
            <button
              type='button'
              className={classNames(
                "relative inline-flex items-center rounded-[0.15rem] px-2 py-1 tracking-wide text-md font-avenirmedium text-gray-900 focus:z-10",
                {
                  "bg-pkl-500 text-white": benchmark == "3.5",
                  "bg-gray-300 hover:bg-pkl-100": benchmark !== "3.5",
                }
              )}
              onClick={() => setBenchmark("3.5")}>
              3.5
            </button>
            <button
              type='button'
              className={classNames(
                "relative inline-flex items-center rounded-[0.15rem] px-2 py-1 tracking-wide text-md font-avenirmedium text-gray-900 focus:z-10",
                {
                  "bg-pkl-500 text-white": benchmark == "4.0",
                  "bg-gray-300 hover:bg-pkl-100": benchmark !== "4.0",
                }
              )}
              onClick={() => setBenchmark("4.0")}>
              4.0
            </button>
            <button
              type='button'
              className={classNames(
                "relative inline-flex items-center rounded-[0.15rem] px-2 py-1 tracking-wide text-md font-avenirmedium text-gray-900 focus:z-10",
                {
                  "bg-pkl-500 text-white": benchmark == "4.5",
                  "bg-gray-300 hover:bg-pkl-100": benchmark !== "4.5",
                }
              )}
              onClick={() => setBenchmark("4.5")}>
              4.5
            </button>
            <button
              type='button'
              className={classNames(
                "relative inline-flex items-center rounded-[0.15rem] px-2 py-1 tracking-wide text-md font-avenirmedium text-gray-900 focus:z-10",
                {
                  "bg-pkl-500 text-white": benchmark == "5.0",
                  "bg-gray-300 hover:bg-pkl-100": benchmark !== "5.0",
                }
              )}
              onClick={() => setBenchmark("5.0")}>
              5.0
            </button>
            <button
              type='button'
              className={classNames(
                "relative inline-flex items-center rounded-[0.15rem] px-2 py-1 tracking-wide text-md font-avenirmedium text-gray-900 focus:z-10",
                {
                  "bg-pkl-500 text-white": benchmark == "Pro",
                  "bg-gray-300 hover:bg-pkl-100": benchmark !== "Pro",
                }
              )}
              onClick={() => setBenchmark("Pro")}>
              Pro
            </button>
          </span>
        </div>
        {/* heading */}
        <div className='font-avenirdemibold text-center justify-center'>
          Unreturned Rates
          <div>Lower is better</div>
        </div>
        <div className='justify-center min-w-min max-w-16 text-center grid grid-cols-1 lg:grid-cols-2 lg:space-x-4'>
          {/* labels*/}

          <div className='grid grid-cols-4 text-center font-avenirdemibold text-3xl'>
            <div className='col-span-2'></div>
            <div className='text-lg'>You</div>
            <div className='text-lg'>Avg. {benchmark} </div>
          </div>
          <div className='hidden lg:grid lg:grid-cols-4 text-center font-avenirdemibold text-3xl'>
            <div className='col-span-2'></div>
            <div className='text-lg'>You</div>
            <div className='text-lg'>Avg. {benchmark} </div>
          </div>

          {/* stats */}

          <div className='grid grid-cols-4 h-20   items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center '>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>
                Serve
              </p>
              <img className='w-48 h-12 object-contain' src={serve} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500":
                    serveUnreturnedPercent <= comps.srv_unreturned_pct,
                  "bg-[#FC9E7C] ":
                    serveUnreturnedPercent > comps.srv_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(serveUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold text-center self-center '>
                {(comps.srv_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>

          <div className='grid grid-cols-4 h-20  items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center '>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>
                Return
              </p>
              <img className='w-48 h-12 object-contain' src={returnImage} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500 ":
                    returnUnreturnedPercent <= comps.rtrn_unreturned_pct,
                  "bg-[#FC9E7C]":
                    returnUnreturnedPercent > comps.rtrn_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(returnUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold  text-center self-center '>
                {(comps.rtrn_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>

          <div className='grid grid-cols-4 h-20  items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center '>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>
                Third Shot Drop
              </p>
              <img className='w-48 h-12 object-contain' src={drop} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500 ":
                    thirdShotDropUnreturnedPercent <=
                    comps.ts_drp_unreturned_pct,
                  "bg-[#FC9E7C]":
                    thirdShotDropUnreturnedPercent >
                    comps.ts_drp_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(thirdShotDropUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold text-center self-center '>
                {(comps.ts_drp_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>

          <div className='grid grid-cols-4 h-20 items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center'>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>
                Transition Appr
              </p>
              <img className='w-48 h-12 object-contain' src={trans} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500 ":
                    transitionZoneApproachUnreturnedPercent <=
                    comps.tz_appr_unreturned_pct,
                  "bg-[#FC9E7C]":
                    transitionZoneApproachUnreturnedPercent >
                    comps.tz_appr_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(transitionZoneApproachUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold  text-center self-center '>
                {(comps.tz_appr_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>

          <div className='grid grid-cols-4 h-20 items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center'>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>
                Transition Repel
              </p>
              <img className='w-48 h-12 object-contain' src={repel} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500 ":
                    transitionRepelUnreturnedPercent <=
                    comps.tz_repel_unreturned_pct,
                  "bg-[#FC9E7C]":
                    transitionRepelUnreturnedPercent >
                    comps.tz_repel_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(transitionRepelUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold  text-center self-center '>
                {(comps.tz_repel_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>

          <div className='grid grid-cols-4 h-20 items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center'>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>Dink</p>
              <img className='w-48 h-12 object-contain' src={dink} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500 ":
                    dinkUnreturnedPercent <= comps.dink_unreturned_pct,
                  "bg-[#FC9E7C]":
                    dinkUnreturnedPercent > comps.dink_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(dinkUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold  text-center self-center '>
                {(comps.dink_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>

          <div className='grid grid-cols-4 h-20 items-center text-center py-2 mb-2'>
            <div className='col-span-2 flex flex-col justify-center items-center'>
              <p className='font-avenirdemibold text-xl text-[#5a5a5a]'>
                Speed Up
              </p>
              <img className='w-48 h-12 object-contain' src={volley} />
            </div>

            <div
              className={classNames(
                "flex justify-center h-[100%] rounded-l-md",
                {
                  "bg-pkl-500 ": spUnreturnedPercent <= comps.sp_unreturned_pct,
                  "bg-[#FC9E7C]": spUnreturnedPercent > comps.sp_unreturned_pct,
                }
              )}>
              <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
                {(spUnreturnedPercent * 100).toFixed(1)}%
              </p>
            </div>
            <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
              <p className='text-3xl font-avenirdemibold  text-center self-center '>
                {(comps.sp_unreturned_pct * 100).toFixed(1)}%
              </p>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-4 h-20 items-center text-center py-2'>
          <div className='col-span-2 flex flex-col justify-center items-center'>
            {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='red'
              dataslot='icon'
              className='h-12'>
              <path
                fillRule='evenodd'
                d='M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z'
                clipRule='evenodd'
              />
            </svg> */}
            <p className='font-avenirdemibold text-3xl'>Overall Rate</p>
          </div>

          <div
            className={classNames("flex justify-center h-[100%] rounded-l-md", {
              "bg-pkl-500 ":
                overAllUnreturnedPercent <= comps.overall_unreturned_pct,
              "bg-[#FC9E7C]":
                overAllUnreturnedPercent > comps.overall_unreturned_pct,
            })}>
            <p className='text-3xl font-avenirdemibold text-white text-center self-center '>
              {(overAllUnreturnedPercent * 100).toFixed(1)}%
            </p>
          </div>
          <div className='bg-[#D8EFEB] h-full flex justify-center rounded-r-md'>
            <p className='text-3xl font-avenirdemibold  text-center self-center '>
              {(comps.overall_unreturned_pct * 100).toFixed(1)}%
            </p>
          </div>
        </div>

        <div className='text-center'>
          <h4 className='font-avenirdemibold text-4xl mt-8 mb-4'>
            Road To Leveling Up!
          </h4>
          <p className='text-xl'>
            Want to win more matches? Based on your unreturned rates compared to
            similar players, you could improve the most by working on these
            shots!
          </p>
        </div>
      </div>
    </>
  );
}
