import React from 'react'
import pklmart from '../../assets/pklmart-logo.png'
import close from '../../assets/back-arrow.svg'


function PictureView({ visible, setVisible }) {
    return (
        visible ? <>
            <div
                onWheel={() => {
                    setVisible()
                }}
                onClick={() => {
                    setVisible()
                }} className='absolute bg-black bg-opacity-75 w-full h-full top-0 left-0 z-40'>

            </div>
            <div onWheel={() => {
                setVisible()
            }} className='absolute bg-black bg-opacity-20 shadow-lg w-1/2 h-1/2 min-w-80  top-1/4 left-1/4 z-50'>
                <div onClick={() => {
                    setVisible()
                }} className='absolute right-4 top-2 text-4xl font-bold  text-white' >X</div>
                <img src={pklmart} className='w-full h-full object-contain' />
            </div></> : <></>

    )
}

export default PictureView