import { useRef, useEffect, useState } from "react";
import useSWR from "swr";
import { API_HOST, fetcher } from "./config";
import { Link, Route, Switch } from "wouter";
import Loading from "./components/Loading";
import { BarGraph, DensityGraph, RadarGraph } from "./components/Graphs";
import { BarGraphRed } from "./components/Graphs/BarGraphRed";
import { PlayerHighlightsTable } from "./components/PlayerHighlights";
import { PlayerHistoryTable } from "./components/PlayerHistory";
import { AppHeader } from "./components/Layout";
import { PlayerReturns } from "./components/PlayerReturns";
import classNames from "classnames";

import {
  PlayerHeader,
  PlayerRecentMatches,
  ErrorRates,
  PlayerPeers,
} from "./components/PlayerPage";
import UnreturnedRates from "./components/PlayerPage/UnreturnedRates";

export default function Player({ pid }) {
  const { data, error, isLoading } = useSWR(
    `${API_HOST}/players/${pid}`,
    fetcher
  );

  const [showError, setShowError] = useState(true);
  const [tabSelected, setTabSelected] = useState(2);

  if (isLoading && !data?.player) {
    return <Loading isLoading={true} />;
  }

  const { first_nm, last_nm, gender, dom_hand } = data.player;

  const { singles_dupr, doubles_dupr, dupr_code, age, shortaddress } =
    data.playerDupr;

  const {
    overall_error_pct,
    srv_error_pct,
    rtrn_error_pct,
    ts_drp_error_pct,
    ts_drv_error_pct,
    tz_appr_error_pct,
    tz_repel_error_pct,
    dink_error_pct,
    sp_error_pct,
  } = data.playerErrorRates;

  const {
    srv_unreturned_pct,
    rtrn_unreturned_pct,
    ts_drp_unreturned_pct,
    ts_drv_unreturned_pct,
    tz_appr_unreturned_pct,
    tz_repel_unreturned_pct,
    dink_unreturned_pct,
    sp_unreturned_pct,
    overall_unreturned_pct,
  } = data.playerUnreturnedRates;

  // console.log(data);

  return (
    <>
      <div className='h-full font-avenir'>
        <AppHeader />

        <div className='mx-4 md:mx-8 '>
          <PlayerHeader
            age={age}
            shortaddress={shortaddress}
            singles_dupr={singles_dupr}
            doubles_dupr={doubles_dupr}
            dupr_code={dupr_code}
            shotsCount={data.shotsCount}
            ralliesCount={data.ralliesCount}
            gamesCount={data.gamesCount}
            first_nm={first_nm}
            last_nm={last_nm}
            gender={gender}
            dom_hand={dom_hand}
          />

          {/* START OF PROFILE ROUTER */}
          {/* DESKTOP for now */}
          <div className='mx-16 grid grid-cols-4 items-center justify-center mt-4 bg-green-100 font-avenirmedium text-xl'>
            <div className='text-center'>
              <div
                onClick={() => {
                  setTabSelected(0);
                }}
                className={
                  tabSelected === 0
                    ? "bg-pkl-400"
                    : "bg-pkl-100 hover:bg-pkl-500 cursor-pointer"
                }>
                <div>Icon</div>
                <div>Profile</div>
              </div>
            </div>
            <div
              onClick={() => {
                setTabSelected(1);
              }}
              className='text-center '>
              <div
                className={
                  tabSelected === 1
                    ? "bg-pkl-400"
                    : "bg-pkl-100 hover:bg-pkl-500 cursor-pointer"
                }>
                <div>Icon</div>
                <div>Analytics</div>
              </div>
            </div>
            <div className='text-center '>
              <div
                onClick={() => {
                  setTabSelected(2);
                }}
                className={
                  tabSelected === 2
                    ? "bg-pkl-400"
                    : "bg-pkl-100 hover:bg-pkl-500 cursor-pointer"
                }>
                <div>Icon</div>
                <div>Matches</div>
              </div>
            </div>
            <div className='text-center '>
              <div
                onClick={() => {
                  setTabSelected(3);
                }}
                className={
                  tabSelected === 3
                    ? "bg-pkl-400"
                    : "bg-pkl-100 hover:bg-pkl-500 cursor-pointer"
                }>
                <div>Icon</div>
                <div>Highlights</div>
              </div>
            </div>
          </div>

          <div className='my-0 lg:flex border-b-4 border-[#18BC9C] pb-4'>
            <PlayerRecentMatches matches={data.matches} />
            <PlayerPeers peers={data.peers.slice(0, 5)} />
          </div>

          <div className='border-b-4 border-[#18BC9C] pb-4 mt-4  '>
            <div className='font-avenirdemibold text-center self-center align-center justify-center text-2xl'>
              Shot Type Analysis
            </div>
            <div className='text-center flex flex-col items-center mt-4 '>
              Select stat type
              <span className='isolate inline-flex space-x-2 md:space-x-4'>
                <button
                  type='button'
                  className={classNames(
                    "relative inline-flex items-center rounded-[0.25rem] px-2 md:px-8 lg:px-8 py-1 tracking-wide  text-gray-900  focus:z-10",
                    {
                      "bg-pkl-500 text-white": showError,
                      "bg-gray-300 hover:bg-pkl-100": !showError,
                    }
                  )}
                  onClick={() => setShowError(true)}>
                  <h3 className='text-center font-avenirmedium  text-md lg:text-lg'>
                    Unforced Error Rates
                  </h3>
                </button>
                <button
                  type='button'
                  className={classNames(
                    "relative -ml-px inline-flex items-center rounded-[0.25rem] px-2 lg:px-8 py-1 tracking-wide text-gray-900 ring-gray-300  focus:z-10",
                    {
                      "bg-pkl-500 text-white": !showError,
                      "bg-gray-300 hover:bg-pkl-100": showError,
                    }
                  )}
                  onClick={() => setShowError(false)}>
                  <h3 className='text-center font-avenirmedium text-md lg:text-lg'>
                    Unreturned Shot Rates
                  </h3>
                </button>
              </span>
            </div>
            {showError ? (
              <ErrorRates
                playerId={pid}
                overAllErrorPercent={overall_error_pct}
                serveErrorPercent={srv_error_pct}
                returnErrorPercent={rtrn_error_pct}
                thirdShotDriveErrorPercent={ts_drv_error_pct}
                thirdShotDropErrorPercent={ts_drp_error_pct}
                transitionZoneApproachErrorPercent={tz_appr_error_pct}
                transitionRepelErrorPercent={tz_repel_error_pct}
                dinkErrorPercent={dink_error_pct}
                spErrorPercent={sp_error_pct}
                errorRates={data.errorRates}
              />
            ) : (
              <UnreturnedRates
                playerId={pid}
                overAllUnreturnedPercent={overall_unreturned_pct}
                serveUnreturnedPercent={srv_unreturned_pct}
                returnUnreturnedPercent={rtrn_unreturned_pct}
                thirdShotDriveUnreturnedPercent={ts_drv_unreturned_pct}
                thirdShotDropUnreturnedPercent={ts_drp_unreturned_pct}
                transitionZoneApproachUnreturnedPercent={tz_appr_unreturned_pct}
                transitionRepelUnreturnedPercent={tz_repel_unreturned_pct}
                dinkUnreturnedPercent={dink_unreturned_pct}
                spUnreturnedPercent={sp_unreturned_pct}
                unreturnedRates={data.unreturned_rates}
              />
            )}
          </div>

          <PlayerHighlightsTable data={data.highlights} />

          {/* <h1 className="my-8 text-4xl font-bold">Player {first_nm} {last_nm}</h1>
            <div className="flex">
              <div style={{width: "650px"}}>
                <h3 className="mb-2 text-xl font-bold">Player information</h3>
                <ul className="mb-8">
                  <li>Age:</li>
                  <li>Paddle:</li>
                  <li>Gender: {gender === 'M' ? 'Male' : 'Female'}</li>
                  <li>Handedness: {dom_hand === 'R' ? 'Right' : 'Left'}</li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-bold">Player Stats</h3>
                <RadarGraph name={first_nm} />
              </div>
            </div>


            <div className="flex">
              <div className="mr-8">
                <PlayerHistoryTable data={data.matches} />
              </div>
            </div>

            <div className="flex">
              <div className="mr-8">
                <h3 className="mb-8 text-xl font-bold">Serve Error</h3>
                <BarGraphRed />
              </div>
              <div>
                <h3 className="mb-8 text-xl font-bold">Forced Return Error</h3>
                <BarGraph />
              </div>
            </div>

            <h3 className="mb-8 text-xl font-bold">Serve Heatmap</h3>
            <DensityGraph />
          */}
        </div>
      </div>
    </>
  );
}
